export interface Client {
  id: string;
  name: string;
  logo?: string;

  config: {
    dpi: number[];
    formats: string[];
    dimensions: {
      min: number;
    };
    ratioOffset: number;
  };
}

export interface ClientDetails {
  id: string;
  name: string;
  enterprise: boolean;
  apiKey?: string;
}

export enum ClientWebhookEvent {
  order_shipped = 'order_shipped',
}

export interface ClientWebhook {
  id: string;
  email: string;
  events: ClientWebhookEvent[];
}
